import React from "react"
import PhoneIcon from "../assets/icons/phone.svg"
import MailIcon from "../assets/icons/mail.svg"
import Image from "../components/image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faArrowUp } from "@fortawesome/free-solid-svg-icons"

import footerStyles from "./footer.module.css"

const Footer = () => {
  const onClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  return (
    <footer className={footerStyles.footer} data-aos="fade-right">
      <section
        id="contact"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <h2 className={footerStyles.title}>Nous contacter</h2>
        <div className={footerStyles.contact}>
          <MailIcon className={footerStyles.icon} />
          <a
            className={footerStyles.link}
            href="mailto:carwashautomateservice@gmail.com"
          >
            carwashautomateservice@gmail.com
          </a>
        </div>
        <div className={footerStyles.contact}>
          <PhoneIcon className={footerStyles.icon} />
          <a className={footerStyles.linkPhone} href="tel:0696016395">
            0696 01 63 95
          </a>
        </div>
        <a
          href="https://www.facebook.com/CAR-WASH-100718448156816"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            color: "white",
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Suivez-nous <span className="sr-only"> sur Facebook</span>
          <FontAwesomeIcon
            style={{
              background: "#3B5998",
              color: "white",
              width: "40px",
              padding: "4px",
              height: "30px",
              marginLeft: "15px",
            }}
            icon={faFacebook}
          />
        </a>
        <div
          className={footerStyles.logo}
        >
          <Image name="logo" alt="" />
        </div>
      </section>

      <button
        type="button"
        onClick={onClick}
        className={footerStyles.backTop}
        data-aos="slide-up"
        aria-label="revenir en haut de la page"
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </button>
    </footer>
  )
}

export default Footer
