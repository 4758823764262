import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import Image from "./image"
import headerStyles from "./header.module.css"

const Header = ({ siteTitle }) => {
  const [isSticky, setSticky] = useState(false)

  const ref = useRef(null)
  const handleScroll = () => {
    if (ref.current) {
      setSticky(window.pageYOffset > ref.current.offsetTop)
    }
  }

  useEffect(() => {
    // reset hash
    if (window.location.hash) {
      window.location.hash = ""
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  // simple function to use for callback in the intersection observer
  const changeNav = (entries, observer) => {
    const activeClass = headerStyles.active
    entries.forEach((entry) => {
      // verify the element is intersecting
      if (entry.isIntersecting && entry.intersectionRatio >= 0.55) {
        // remove old active class
        document
          .querySelector(`.${activeClass}`)
          .classList.remove(`${activeClass}`)
        // get id of the intersecting section
        const id = entry.target.getAttribute("id")
        // find matching link & add appropriate class
        document
          .querySelector(`[href="#${id}"]`)
          .classList.add(`${activeClass}`)

        if (window.location.hash !== `#${id}`) {
          window.history.pushState(null, null, `#${id}`)
        }
      }
    })
  }

  if (typeof window !== "undefined") {
    // init the observer
    const options = {
      threshold: 0.55,
    }

    const observer = new window.IntersectionObserver(changeNav, options)

    // target the elements to be observed
    const sections = document.querySelectorAll("section, header")
    sections.forEach((section) => {
      observer.observe(section)
    })
  }

  return (
    <header
      id="accueil"
      ref={ref}
      className={[
        headerStyles.header,
        ` ${isSticky ? headerStyles.sticky : ""}`,
      ].join("")}
    >
      <h1 className="sr-only">{siteTitle}</h1>
      <div className={headerStyles.logo}>
        <Image name="logo" alt="car wash logo" />
      </div>
      <nav ref={ref} className={headerStyles.nav}>
        <ul className={headerStyles.navLink}>
          <li>
            <a href="#accueil" className={headerStyles.active}>
              accueil
            </a>
          </li>

          <li>
            <a href="#formules">formules</a>
          </li>

          <li>
            <a href="#horaires">horaires</a>
          </li>

          <li>
            <a href="#itineraire">itinéraire</a>
          </li>

          <li>
            <a href="#contact">contact</a>
          </li>
        </ul>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
