import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({ name, alt, withStyles }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "car-wash-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 698, maxHeight: 51, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      iconCleaning: file(relativePath: { eq: "cleaning-service.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hoursPhoto: file(relativePath: { eq: "carwashHours.jpeg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data[name].childImageSharp.fluid}
      style={withStyles ? withStyles : {}}
      // imgStyle={{ objectFit: "contain" }}
      alt={alt}
      aria-hidden={true}
    />
  )
}

export default Image
